.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  text-align: center;

  /* @media only screen and (max-width: 900px) {
    text-align: center;
  } */
}

.labelHomeMobileBlock {
  /* display: inline-block;
  padding: 8px 20px; */
  font-size: 25px;
  /* background-color: var(--marketplaceColor); */
  /* border-radius: 4px; */
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 600;
  margin-top: 24px;
  line-height: 1;
  text-align: left;
}
