.wrapper {
    border-radius: 29.5px 0px 0px 29.5px;
    position: relative;
    margin: 0 auto;
    max-width: 532px;
}

.icon {
    height: 1.5rem;
    width: 1.5rem;
    padding-left: 3%;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 1%;
    transform: translateY(-50%);
    stroke: var(--colorGrey700);
}


@media (max-width:600px) {
    .input {
        height: 60px;
        box-sizing: border-box;
        padding-left: 22%;
        border-radius: 29.5px 0px 0px 29.5px;
    }
}

@media (min-width:600px) {
    .input {
        height: 60px;
        box-sizing: border-box;
        padding-left: 16%;
        border-radius: 29.5px 0px 0px 29.5px;
    }
}

.myCurrentLocationLabel {
    background-color: var(--marketplaceColor);
    color: white;
    padding-left: 5%;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
}

.searchSubmit {
    border-radius: 0px 29.5px 29.5px 0px;
    border: transparent;
    background-color: var(--marketplaceColor);
    position: absolute;
    right: 0%;
    top: 0%;
    width: 30%;
    height: 60px;
    color: white;
}

.dataResult {
    border-radius: 5px;
    background: white;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    width: 95%;
    margin-left: 5%;

}

.dataResult::-webkit-scrollbar {
    display: none;
}

.dataItem {
    /* padding: 24px 32px; */
    padding-left: 5%;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    color: var(--marketplaceColor);
}

.dataItem:hover {
    background-color: #e9e9e9;
}
